import React, { useState, useEffect, useRef, useCallback } from "react";
import ".././components/DetallesObra.css";
import { FiCalendar } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  setAlbaranes,
  setAlbaranPendiente,
  setCamiones,
  setObras,
  setTipos,
  setVertederos,
} from "../redux/actions";
import { crearAlbaran } from "../pluggins/albaranes";
import { Detector } from "react-detect-offline";
import {
  fetchAlbaranes,
  fetchCamiones,
  fetchObras,
  fetchPreferences,
  fetchTipos,
  fetchVertederos,
} from "../services/albaranes";
import { format } from "date-fns";
import https from "../pluggins/http";
import queryString from "query-string";
import { is } from "date-fns/locale";
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const DetallesObra = ({ location }) => {
  async function setUser() {
    try {
      const response = await https.post("/getConductor", {
        id: query.idConductor,
      });
      if (response?.data?.data) {
        setUsuario(response?.data?.data);
        userSet(true);
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.data);
    }
  }
  let history = useHistory();
  const [isUserSet, userSet] = useState(false);
  const query = queryString.parse(location.search);
  const dispatch = useDispatch();
  const [user, setUsuario] = useState({});
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);
  const aux = useSelector((state) => state?.auth?.currentUser?.data);
  const camiones = useSelector((state) =>
    Array.isArray(state?.app?.camiones) ? state?.app?.camiones : []
  );
  const obras = useSelector((state) =>
    Array.isArray(state?.app?.obras) ? state?.app?.obras : []
  );
  const tipos = useSelector((state) =>
    Array.isArray(state?.app?.tipos) ? state?.app?.tipos : []
  );
  const vertederos = useSelector((state) =>
    Array.isArray(state?.app?.vertederos) ? state?.app?.vertederos : []
  );
  const albaranPendiente = useSelector((state) => state?.app?.albaranPendiente);

  const [isLoading, setLoading] = useState(true);
  const [m3Data, setm3Data] = useState("");
  const [peso, setPeso] = useState("");
  const [m3DataMounted, setm3DataMounted] = useState(false);
  const [nombreConductor, setNombreConductor] = useState("");
  const [matricula, setMatricula] = useState("");
  const [coments, setComents] = useState("");

  const current = new moment();
  const day = current.format("D");
  const month = current.format("MM");
  const year = current.format("YYYY");
  const hours = current.format("HH");
  const minuts = current.format("mm");

  const [fecha_day, setFecha_day] = useState(day);
  const [fecha_month, setFecha_month] = useState(month);
  const [fecha_year, setFecha_year] = useState(year);
  const [obrasid, setObrasid] = useState("");
  const [nombrevertedero, setNombreVertedero] = useState("");
  const [idvertedero, setIdVertedero] = useState("");
  const [vertederosFlexibles, setVertederosFlexibles] = useState("");
  const [codigo_LER, setCodigo_LER] = useState("");
  const [tiposId, setTiposId] = useState();
  const [camion_id, setIdCamiones] = useState();
  const [conductor_id, setconductor_id] = useState("");
  const [preferences, setDriverPreferences] = useState();
  const [isMounted, setisMounted] = useState(false);
  const [isObraMounted, setisObraMounted] = useState(false);
  const [, setidExterno] = useState([]);
  const [tipoResiduo] = useState([]);
  const [tiposResiduos, setTiposResiduos] = useState([]);
  const [obraComodin, setObraComodin] = useState(null);
  // const [albaranPendiente, setAlbaranPendiente] = useState(null);
  const [creating, setCreating] = useState(false);
  console.log("CAMION ID AHORA ES", camion_id);
  const [isVisible, setVisible] = useState("none");

  const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  const isCreating = !!creating || !!albaranPendiente;

  const handleSetPreferences = async () => {
    console.log("USER", user);
    let response = await fetchPreferences(user?.id);

    console.log("EL CONDUCTOR ES", response);

    setIdCamiones(
      response?.data?.camion
        ? response?.data?.camion?.id
        : user?.camion_asignado
    );

    if (response?.data?.obra) {
      setObrasid(response?.data?.obra?.id);
      setisObraMounted(true);
      setTiposResiduos(response?.data?.obra?.residuos_fijos.split(","));
    }
    setTiposId(response?.data?.tipo_residuo?.id);

    setLoading(false);
  };

  console.log("TIPO", tiposId);

  const createAndUpdateComodin = async () => {
    if (obraComodin != null) {
      try {
        const response = await https.post("createObra", {
          nombre: "OBRA COMODIN",
          cif: "-",
          vertedero: idvertedero,
          vertederos: idvertedero,
          destino: idvertedero,
          direccion: "-",
          telf: "-",
          operador: user.operador,
          active: 0,
        });
      } catch (error) {
        alert(error.response.data.message);
      }

      try {
        const response = await https.post("updateObra", {
          id: obrasid,
          nombre: obraComodin,
        });
      } catch (error) {
        alert(error.response.data.message);
      }
      _handlePostAlbaran();
    } else {
      alert("El nombre de la nueva obra no puede estar vacío.");
    }
  };
  const getAlbaranes = useCallback(async () => {
    if (window.navigator.onLine) {
      let filtro = "";
      console.log(user);
      // let preferenceResponse = await fetchPreferences({ id: user?.id });

      if (rol === "vertedero") {
        filtro = "" + format(new Date(), "dd/MM/yyyy");
      }
      const response = await fetchAlbaranes({
        id: user.id,
        rol: rol,
        fecha: filtro,
      });
      dispatch(setAlbaranes(response));
    }
  }, [dispatch, rol, user.id]);

  const _handlePostAlbaran = async () => {
    setCreating(true);
    let fechaAlbaran = fecha_day + "-" + fecha_month + "-" + fecha_year;
    let fecha = moment(fechaAlbaran, "DD-MM-YYYY");
    const selectedCamion = camiones.find((option) => option.id === camion_id);
    console.log("camion id es ", camion_id);
    if (fecha?.isValid()) {
      const payload = {
        hora: moment("" + hours + minuts, "hmm").format("HH:mm"),
        codigo_LER: codigo_LER,
        m3_Kg: m3Data,
        peso: peso,
        estado: "active",
        operador: user.operador,
        comentarios: coments,
        fecha: moment(
          fecha_day + "/" + fecha_month + "/" + fecha_year,
          "DD MM YYYY"
        ).format("DD/MM/YYYY"),
        conductor: nombreConductor,
        conductor_id: conductor_id,
        camion: selectedCamion?.matricula,
        destino: rol === "vertedero" ? aux.id : idvertedero,
        // destino: 36,
        obra: obrasid,
        tipo_residuo: tiposId,
        tipoResiduo,
      };

      console.log("payload", payload);
      if (window.navigator.onLine) {
        await _processAlbaran(payload);
      } else {
        setCreating(false);
        dispatch(setAlbaranPendiente(payload));
      }
    } else {
      setCreating(false);
      alert("La fecha introducida no es una fecha válida");
    }
  };

  const _processAlbaran = async (payload) => {
    try {
      const response = await crearAlbaran(payload);
      await getAlbaranes();
      setCreating(false);
      console.log(response);
      if (response?.data?.data) {
        if (response?.data?.data && rol === "vertedero") {
          history.push("/Validation?id=" + response?.data?.data.id);
        }
        if (response?.data?.data && rol !== "vertedero") {
          history.push(
            "/Confirmacion",
            { frase: "Albarán de vertido creado correctamente" },
            { tipoResiduo: payload?.tipoResiduo }
          );
        }
      } else {
        alert(response?.data?.message);
      }
    } catch (e) {
      setCreating(false);
      console.log("entra al catch");
      alert(e.response.data.message);
    }
  };

  const handleSetObras = useCallback(
    (data = []) => {
      console.log("ESTA ES LA DATA", data);
      setidExterno(data[data?.length - 1]?.conductores?.split(",") || []);
      let founded = false;
      for (let i = 0; i < data.length; i++) {
        if (
          (data[i]?.operador == aux?.operador ||
            ("" + data[i].conductores).split(",").indexOf("" + aux.id) > -1) &&
          data[i].active === 0 &&
          data[i].comodin === null
        ) {
          console.log("CONSOLE LOG NUEVO" + data[i].obra_id);
          setObrasid(data[i].obra_id);
          setNombreVertedero(data[i]?.vertederos_nombre);
          setIdVertedero(data[i]?.vertederos_id);
          data[i]?.vertederos_flexibles
            ? setVertederosFlexibles(data[i]?.vertederos_flexibles.split(","))
            : setVertederosFlexibles([]);
          console.log("ESTE ES EL NOMBRE", data[i]?.vertederos_id);
          return;
        } else {
          console.log("no coincide", data[i].operador, aux.operador);
        }
      }
    },
    [setidExterno]
  );

  const getObras = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchObras({
          operador: user?.operador,
          conductor_id: user?.id,
        });
        if (Array.isArray(response)) {
          dispatch(setObras(response));
          handleSetObras(response);
          console.log("LAS OBRAS SON", response);
          setTiposResiduos(response[0]?.residuos_fijos.split(","));
        }
      } catch (_error) {
        handleSetObras(obras);
      }
    } else {
      handleSetObras(obras);
    }
  }, [dispatch, handleSetObras, obras, user]);

  const getTipos = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchTipos();
        if (Array.isArray(response)) {
          dispatch(setTipos(response));
        }
      } catch (_error) {}
    }
  }, [dispatch, tipos]);

  const handleSetCamiones = useCallback(
    (data = []) => {
      if (!m3DataMounted) {
        console.log("EL CAMION ASIGNADO ES", user?.camion_asignado);
        const finded = data?.find(
          (option) => option.id === user?.camion_asignado.id
        );
        if (finded) {
          setm3Data(finded.capacidad);
          setMatricula(finded.matricula);
          setm3DataMounted(true);
        }
      }
      setIdCamiones(user?.camion_asignado?.id);
    },
    [setm3Data, setMatricula, m3DataMounted, user]
  );
  console.log("m3data es", m3Data);
  const getCamiones = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchCamiones({ id: user?.operador });
        if (Array.isArray(response)) {
          dispatch(setCamiones(response));
          handleSetCamiones(response);
        }
      } catch (_error) {
        handleSetCamiones(camiones);
      }
    } else {
      handleSetCamiones(camiones);
    }
  }, [user, dispatch, handleSetCamiones, camiones]);

  const getVertederos = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchVertederos();
        if (Array.isArray(response)) {
          dispatch(setVertederos(response));
        }
      } catch (_error) {}
    }
  }, [dispatch]);

  const tiposRef = useRef("");

  const handleTiposchange = (e) => {
    console.log("paso por aqui");
    console.log(tiposId);
    setTiposId(e.target.value);
    tipos.map((option) =>
      "" + option.id === "" + e.target.value
        ? setCodigo_LER(option.codigo_ler)
        : null
    );
  };

  const handleSetTipos = (e) => {
    console.log("entra aqui macho");
    setTiposId(e);
    tipos.map((option) =>
      "" + option.id === "" + e ? setCodigo_LER(option.codigo_ler) : null
    );
  };

  useEffect(() => {
    if (rol !== "vertedero") {
      if (tiposRef) {
        handleSetTipos(tiposRef.current.value);
      }
    }

    if (!isUserSet) {
      if (rol === "vertedero") {
        setUser();
      } else {
        setUsuario(aux);
        userSet(true);
      }
    }
    if (!isMounted && isUserSet) {
      getObras();
      console.log("EL USER ES:" + user?.operador);
      getCamiones();
      setNombreConductor(user?.nombre);
      setconductor_id(user?.id);
      if (rol === "vertedero") {
        setIdVertedero(aux.id);
        handleSetPreferences();
      } else {
        setLoading(false);
        getVertederos();
      }
      getTipos();
      setisMounted(true);
    }
  }, [
    getObras,
    getTipos,
    getVertederos,
    getCamiones,
    isMounted,
    user?.id,
    user?.nombre,
    aux,
    rol,
    setUser,
    isUserSet,
    tipos,
    setLoading,
    isLoading,
  ]);
  const toggleVisible = (obra) => {
    if (obra === "OBRA COMODIN") {
      setVisible("block");
    } else {
      setVisible("none");
    }
  };

  const handleObrasChange = (e) => {
    setObrasid(e.target.value);
    console.log("estoy pasando");
    obras.map((option) =>
      "" + option.obra_id === "" + e.target.value
        ? (console.log("verts", option.vertederos_flexibles),
          option.vertederos_flexibles
            ? setVertederosFlexibles(option.vertederos_flexibles.split(","))
            : setVertederosFlexibles([]),
          option.residuos_fijos
            ? setTiposResiduos(option.residuos_fijos.split(","))
            : setTiposResiduos([]),
          setNombreVertedero(option.vertederos_nombre),
          setIdVertedero(option.vertederos_id),
          toggleVisible(option.obra_nombre))
        : null
    );
  };

  const handleVerterderoschange = (e) => {
    setIdVertedero(e.target.value);
    vertederos.map((option) =>
      option.id === e.target.value ? setNombreVertedero(option.nombre) : null
    );
  };

  const handleMatriculaChange = (e) => {
    const selectedId = parseInt(e.target.value);

    const selectedCamion = camiones.find((option) => option.id === selectedId);

    if (selectedCamion) {
      setIdCamiones(selectedId);
      setMatricula(selectedCamion.matricula);
      setm3Data(selectedCamion.capacidad);
    }
  };

  const handleM3Change = (e) => {
    setm3Data(e.target.value);
  };

  const handlePesoChange = (e) => {
    !isNaN(e.target.value) ? setPeso(e.target.value) : setPeso(null);
  };

  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <form>
        {isLoading ? (
          <div className="containerPrincipalDO">
            <div className="loader-container">
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  css={override}
                  size={50} // Tamaño del loader
                  color={"#123abc"} // Color del loader
                  loading={true}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="containerPrincipalDO">
            <div className="containerPopupDO">
              <div>
                <p className="SubTitulo">Selecciona la Obra</p>

                <select
                  name="seleccObraName"
                  onChange={handleObrasChange}
                  className="selectColor selectSizer"
                  disabled={isCreating}
                  value={obrasid}
                >
                  {obras.map((option) =>
                    option.comodin === null
                      ? (!isObraMounted
                          ? (setObrasid(option.obra_id), setisObraMounted(true))
                          : null,
                        (
                          <option value={option.obra_id}>
                            {option.obra_nombre}
                          </option>
                        ))
                      : null
                  )}
                </select>
              </div>
              <div>
                <p className="SubTitulo">Destino</p>
                <select
                  name="destinoName"
                  onChange={handleVerterderoschange}
                  className="selectColor selectSizer"
                  disabled={isCreating}
                  value={idvertedero}
                >
                  {rol === "vertedero" ? (
                    <option value={aux.id}> {aux.nombre}</option>
                  ) : (
                    vertederos.map((option, i) =>
                      option.estado === 0 && option.id == idvertedero ? (
                        <option value={option.id} selected key={`option-${i}`}>
                          {option.nombre}
                        </option>
                      ) : vertederosFlexibles.length === 0 ||
                        vertederosFlexibles.includes(option.id.toString()) ? (
                        <option value={option.id} key={`option-${i}`}>
                          {option.nombre}
                        </option>
                      ) : null
                    )
                  )}
                </select>
              </div>
              <div className="subContainerm3">
                <div className="flexContainer1">
                  <p className="SubTitulo">
                    M<sup>3</sup>
                  </p>
                  <input
                    placeholder="m"
                    readOnly
                    className="inputs"
                    value={m3Data}
                    onChange={handleM3Change}
                    disabled={isCreating}
                  />
                </div>
                <div className="flexContainer2">
                  <p className="SubTitulo">Tipo de Residuo</p>
                  <select
                    name="tipoResiduo"
                    onChange={handleTiposchange}
                    className="selectColor selectSizer"
                    disabled={isCreating}
                    ref={tiposRef}
                    value={tiposId}
                  >
                    {tipos.map((option, i) => {
                      if (
                        tiposResiduos.length === 0 ||
                        tiposResiduos.includes(option.id.toString())
                      ) {
                        return (
                          <option value={option.id} key={"key" + i}>
                            {option.nombre} - {option.codigo_ler}
                          </option>
                        );
                      }
                      return "";
                    })}
                  </select>
                </div>
                <div className="flexContainer1">
                  <p className="SubTitulo">Peso(Kg)</p>
                  <input
                    placeholder="KG"
                    className="inputs"
                    value={peso}
                    onChange={handlePesoChange}
                  />
                </div>
              </div>
              <div>
                <p className="SubTitulo">Nombre de Conductor</p>
                <input
                  className="inputs"
                  readOnly
                  value={user?.nombre}
                  disabled={isCreating}
                />
              </div>
              <div>
                <p className="SubTitulo">Matricula</p>
                <select
                  name="matricula"
                  className="selectColor selectSizer"
                  onChange={handleMatriculaChange}
                  disabled={isCreating}
                  value={camion_id}
                >
                  {camiones.map((option, i) => (
                    <option
                      key={i} // Usa el índice como key
                      value={option.id}
                    >
                      {option.matricula}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: isVisible }}>
                <p className="SubTitulo">Nombre y datos de la obra</p>
                <input
                  placeholder="Nombre de la obra"
                  className="inputs comentario"
                  onChange={(e) => setObraComodin(e.target.value)}
                  value={obraComodin}
                  disabled={isCreating}
                />
              </div>
              <div>
                <p className="SubTitulo">Comentarios</p>
                <input
                  placeholder="Comentarios"
                  className="inputs comentario"
                  onChange={(e) => setComents(e.target.value)}
                  value={coments}
                  disabled={isCreating}
                />
              </div>
              <div>
                <div className="fechaIcon">
                  <FiCalendar id="calendarIcon" />
                  <p className="SubTitulo">Fecha</p>
                </div>
                <div className="fecha-input">
                  <input
                    placeholder="DD"
                    className="inputs fecha-dd"
                    onChange={(e) => setFecha_day(e.target.value)}
                    value={fecha_day}
                    disabled={isCreating}
                  />
                  <input
                    placeholder="MM"
                    className="inputs fecha-mm"
                    onChange={(e) => setFecha_month(e.target.value)}
                    value={fecha_month}
                    disabled={isCreating}
                  />
                  <input
                    placeholder="YYYY"
                    className="inputs fecha-yy"
                    onChange={(e) => setFecha_year(e.target.value)}
                    value={fecha_year}
                    disabled={isCreating}
                  />
                </div>
              </div>

              <div className="detector--contaier">
                <Detector
                  render={({ online }) =>
                    !online && (
                      <div className="aviso--offline">
                        Estás sin conexión a internet, el albarán de entrega se
                        creará cuando tengas conexión a internet disponible
                      </div>
                    )
                  }
                  onChange={() => {
                    if (albaranPendiente) {
                      _processAlbaran(albaranPendiente);
                    }
                  }}
                />
              </div>

              <div className="btn">
                <button
                  type="button"
                  className="txtBtnCreaAlbaran"
                  onClick={() => {
                    if (isVisible === "block") {
                      createAndUpdateComodin();
                    } else {
                      _handlePostAlbaran();
                    }
                  }}
                  disabled={isCreating}
                >
                  {isCreating ? (
                    <div className="sk-chase">
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                    </div>
                  ) : (
                    "Crear Albaran"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default DetallesObra;
