import React, { useCallback, useEffect, useState } from "react";
import LogoHeader from "./LogoHeader";
import { FiPlus, FiRefreshCw } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import {
  fetchAlbaranes,
  fetchCamiones,
  fetchObras,
  fetchTipos,
  fetchVertederos,
} from "../services/albaranes";
import {
  setAlbaranes,
  setCamiones,
  setObras,
  setTipos,
  setVertederos,
} from "../redux/actions";
import { Detector } from "react-detect-offline";

function ListScreen() {
  const dispatch = useDispatch();

  //   const [setFilterDate] = useState("");
  const [startDate] = useState(new Date());
  //   const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const user = useSelector((state) => state?.auth?.currentUser?.data);
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);

  const albaranes = useSelector((state) =>
    Array.isArray(state?.app?.albaranes) ? state?.app?.albaranes : []
  );
  const [isConnected, setIsConnected] = useState(false);

  //   const handleChange = (e) => {
  //     setStartDate(e);
  //     setFilterDate("" + format(e, "d/M/yyyy"));
  //     setIsOpen(!isOpen);
  //     setIsFiltered(false);
  //     console.log("hello");
  //   };
  //   const handleClearClick = () => {
  //     setFilterDate("");
  //     setIsFiltered(false);
  //   };

  //   const handleClick = (e) => {
  //     e.preventDefault();
  //     setIsFiltered(false);
  //     setIsOpen(!isOpen);
  //   };

  var pathname = "albaranCreated";

  if (rol === "vertedero") pathname = "validation/";

  var isActive = false;

  const getAlbaranes = useCallback(async () => {
    setIsConnected(window.navigator.onLine);
    if (window.navigator.onLine) {
      let filtro = "";
      if (rol === "vertedero") {
        filtro = "" + format(startDate, "dd/MM/yyyy");
      }
      const response = await fetchAlbaranes({
        id: user.id,
        rol: rol,
        fecha: filtro,
      });
      dispatch(setAlbaranes(response));
    }
  }, [dispatch, rol, startDate, user.id]);

  const getObras = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchObras();
        dispatch(setObras(response));
      } catch (_error) {}
    }
  }, [dispatch]);

  const getTipos = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchTipos();
        dispatch(setTipos(response));
      } catch (_error) {}
    }
  }, [dispatch]);

  const getCamiones = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchCamiones(user?.operador);
        dispatch(setCamiones(response));
      } catch (_error) {}
    }
  }, [dispatch, user?.operador]);

  const getVertederos = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchVertederos();
        dispatch(setVertederos(response));
      } catch (_error) {}
    }
  }, [dispatch]);

  const estadoImage = (estado) => {
    if (estado === "active") {
      isActive = true;
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={require("../assets/images/play.png")} />;
    } else if (estado === "approved") {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={require("../assets/images/tick.png")} />;
    } else if (estado === "denied") {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img src={require("../assets/images/xv3.png")} />;
    } else if (estado === "scanned") {
      return <img src={require("../assets/images/wait.png")} />;
    }
  };

  useEffect(() => {
    if (!isFiltered && user) {
      getAlbaranes();
      setIsFiltered(true);
      getObras();
      getTipos();
      getCamiones();
      getVertederos();
    }
  }, [
    getAlbaranes,
    getCamiones,
    getObras,
    getTipos,
    getVertederos,
    isFiltered,
    user,
  ]);
  sessionStorage.clear();
  //   const urlXlsx =
  //     "https://admin.icargoapp.com/public/file-export?id=" + user.id;
  //   const urlCsv =
  //     "https://admin.icargoapp.com/public/file-export-format?id=" + user.id;

  return (
    <div className="overflow-hidden">
      <div>
        <LogoHeader />
      </div>
      {/* {rol === "vertedero"?
            <div className="filterLista">
                <button className="filterBTn" onClick={handleClick}>
                    Filtrar por fecha
                </button>
                <button className="filterBTn-clear" onClick={handleClearClick}>
                    Limpiar
                </button>
                <div>
                    <a href={urlXlsx}  >
                        <button className="descargarBTnEx">Descargar Xlsx</button>
                    </a>
                    <a href={urlCsv}>
                        <button className="descargarBTnCsv">Descargar Csv</button>
                    </a>
                </div>
                {isOpen && (
                    <DatePicker 
                    locale={"es"}
                    selected={startDate} 
                    dateFormat="DD-MM-YYYY"
                    onChange={handleChange} 
                    inline />
                )}
            </div>
                :null} */}
      <div className="container">
        <button
          onClick={() => window.location.reload()}
          className="reload-button"
        >
          <FiRefreshCw size={24} />
        </button>
        <div className="list-container">
          <div className="detector--contaier">
            <Detector
              render={({ online }) =>
                !online && (
                  <div className="aviso--offline">
                    Estás sin conexión a internet
                  </div>
                )
              }
              onChange={(online) => {
                setIsConnected(online);
                if (!isConnected) {
                  getAlbaranes();
                }
              }}
            />
          </div>
          <div>
            {albaranes
              .sort((a, b) => (a.timeM > b.timeM ? 1 : -1))
              .map((albaran) =>
                rol === "conductor" ? (
                  <Link
                    key={albaran.id}
                    to={{
                      pathname: pathname,
                      albaran: albaran,
                    }}
                  >
                    <div className="item-container">
                      <div>
                        <p>
                          {albaran.ref} {albaran.fecha} {albaran.hora}
                        </p>{" "}
                      </div>
                      <div>{estadoImage(albaran.estado)}</div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    key={albaran.id}
                    to={{
                      pathname: pathname,
                      search: "?id=" + albaran.id + "&route=list",
                      albaran: albaran,
                    }}
                  >
                    <div className="item-container">
                      <div>
                        <p>
                          {albaran.ref} {albaran.fecha} {albaran.hora}
                        </p>{" "}
                      </div>
                      <div>{estadoImage(albaran.estado)}</div>
                    </div>
                  </Link>
                )
              )}
          </div>
          {rol === "conductor" ? (
            <div id="completPlus" className="toggleHide">
              <div className="circle">
                <Link
                  to={{ pathname: "/DetallesObra", state: albaranes }}
                  className="divLinkDetalles"
                >
                  <div className="square">
                    <FiPlus id="plus" />
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
        </div>

        {/* <input accept='image/*' id='icon-button-file' type='file' capture='environment'/> */}
      </div>
    </div>
  );
}

export default ListScreen;
